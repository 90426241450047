import React, {useState} from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {getListItemWrapper, getListItemTitle, getListItemInnerWrapper, getListItemContent, getListItemImage} from '../../utils'

function ListItem({num, page}) {
  const breakpoints = useBreakpoint()
  const [isHovered, setIsHovered]=useState(false)

  if (!page.connectedNode) return null

  {/*
    This takes care of rendering a single Module item in the Landing page.
  */}

  return (
    <Link to={page?.url || page?.connectedNode?.node?.slug}>
      <li
        onMouseEnter={e=>setIsHovered(true)}
        onMouseLeave={e=>setIsHovered(false)}
        className='list-item'
        style={getListItemWrapper(num,breakpoints,isHovered)}>
          {/* Title */}
          <div
            style={getListItemTitle(num)}
            className='list-title'>{page?.connectedNode?.node.title}
          </div>

          {/* Wrapper for Content and Image that changes layout when on Mobile */}
          <div
            style={getListItemInnerWrapper(breakpoints)}>

            {/* Content */}
            <div
              className='list-content'
              style={getListItemContent(breakpoints)}     dangerouslySetInnerHTML={{ __html:    page?.connectedNode?.node?.excerpt?.text }} />

            <div style={getListItemImage(breakpoints)}>
              {/* Check for a thumbnail, and display it */}
              {page?.connectedNode?.node?.featuredImage &&
                <Img
                  fluid={page?.connectedNode?.node?.featuredImage?.node?.localFile?.childImageSharp?.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  alt={page.connectedNode?.node.title} />
              }
            </div>
          </div>
      </li>
    </Link>
   )
}

export default ListItem
