import { useStaticQuery, graphql } from "gatsby"
{/*
  React Hook that fetches the most recent Post.
  It will appear in the sticker underneath the NavBar.
*/}

export const useLatestNewsPost = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(sort: {order: DESC, fields: date}) {
          nodes {
            id
            title
            date
            rsvp {
              rsvp
            }
          }
        }
      }
    `
  )
  return allWpPost.nodes[0]
}
