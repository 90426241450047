import React from "react"
import {useLatestNewsPost} from "../hooks/useLatestNewsPost"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import {getLatestNewsItem} from '../utils'
import sticker from '../images/sticker.png'

function LatestNewsItem() {
  {/* Fetches data from latest News item */}
  const latest = useLatestNewsPost()
  const breakpoints = useBreakpoint()

  return (
    <div
      className='sticker'
      style={getLatestNewsItem(breakpoints, sticker)}>  {/* responsiveness */}
        <a href={latest.rsvp.rsvp}>  {/* sets URL */}
          <p>recently...</p>
          <p>{latest.title}</p>  {/* title */}
        </a>
    </div>
   )
}

export default LatestNewsItem
