import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Header from "../components/header"
import NavBar from "../components/navBar"
import LatestNewsItem from "../components/latestNewsItem"
import {getHomeWrapper, getSideWrapper, getMainWrapper} from "../utils"

import "../styles/layout.css"
import "../styles/globals_kalli.css"
import "../styles/tocbot.css"
import "../styles/inlineWP.css"

{/* This is a layout template file.
  It wraps the Landing page and introduces UI elements that remain consistent like the NavBar and the LatestNewsItem sticker.
*/}


const Home = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryHome {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const breakpoints = useBreakpoint()

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div style={getHomeWrapper(breakpoints)} >
        <div style={getSideWrapper(breakpoints)} >
          <NavBar/>
          <LatestNewsItem />
        </div>
        <main style={getMainWrapper(breakpoints)}>
          {children}
        </main>
      </div>
    </div>
  )
}

Home.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Home
