import React from "react"
import {usePagesFromToolkitMenu} from "../../hooks/usePagesFromToolkitMenu"
import ListItem from './listItem'
import {paletteTitle} from '../../utils'

{/*
  List is where the Module components are listed in the Landing page.
*/}

const List = () => {
  {/*
    Get the Pages from the main Menu.
  */}
  const menuData = usePagesFromToolkitMenu()
  const pages = menuData.map(menu => {return [...menu.menuItems.nodes]}).flat(2)

  return (
    <div
      style={{
        alignSelf: 'flex-start',
        padding: '5px',
        overflowY: 'hidden',
      }}>
      {/*
        Iterate over the pages and render them as "ListItem".
      */}
      {pages.map((page, index) => (
        <div key={index}>
          {/*
            Number of item (above the sticker)
          */}
          <div
           style={{
             color: paletteTitle[index%paletteTitle.length],
             marginTop: '4vh',
             padding: '0'
           }}
           className='subtitle'>no.{index+1}</div>

           {/*
             The individual module component with the Title, Content and Image.
           */}
           <ListItem
            page={page}
            num={index}
            />
        </div>
      ))}
    </div>
   )
}

export default List
