import React from "react"

import Home from "../layouts/home"
import List from "../components/list/list"
import SEO from "../components/seo"

export default function LandingPage() {
  return (
    <Home>
      <SEO title="home" />
      <div>
        {/* Landing page text */}
      <div className='title'>watching the watchers</div>
        <p>Increasingly powerful surveillance tools have shifted the power dynamics between people and institutions. To address this new dynamic, we’ve been creating a toolkit, in collaboration with the ACLU of Washington, that demystifies surveillance technologies in Seattle in the historical context of structural inequities in the United States. Below are a set of guides for facilitating workshops focusing on different aspects of surveillance: historical, visual, physical, and somatic.</p>
        <p>coveillance is a collective of technologists, organizers, and designers who employ arts-based approaches to build communal counterpower. You can stay in touch with us by <a href ="https://lists.coveillance.org/mailman/listinfo/announce_coveillance.org" style = {{color: '#7EB99C',
                textDecoration: 'underline',
                fontWeight: '500',
                fontStyle: 'italic'}}>joining our mailing list.</a> </p>
        <p>NOTE: This toolkit is a first draft, and is still being updated! We would love to hear your feedback or help you deploy our workshops; reach out to us at <a href = "mailto:coveillance@protonmail.com" style = {{color: '#7EB99C',
                textDecoration: 'underline',
                fontWeight: '500',
                fontStyle: 'italic'}}>coveillance@protonmail.com</a>
    
    </p> 
    
      </div>

      {/* List displays the modules in the Landing page */}
      <List />
    </Home>
  )
}
